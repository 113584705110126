.frontpage-highlight-box {
  border: solid 1px var(--chakra-colors-brand-600);
  background-color: #f0f7fb;
  border-radius: 6px;
  margin: -10px;
  padding: 10px;
}

@media print {
  .hideWhenPrinting {
    display: none !important;
  }

  .reportCard {
    box-shadow: none !important;
  }

  .noPaddingTopForPrint {
    padding-top: 0 !important;
  }

  .noPageBreakOnPrint {
    break-inside: avoid-page;
  }

  .chakra-stack {
    display: block !important;
  }

  h1, h2, h3 {
    break-inside: avoid-page;
  }

  h1:after, h2:after, h3:after {
    content: "";
    height: 100px;
    margin-bottom: -100px;
    display: block;
  }

  body {
    background-color: #fff !important;
  }
}

.QAndA-card small {
  color: #757575;
}

.QAndA-card .center-disclaimer {
  text-align: center;
  width: 100%;
}

.QAndA-card .yed-center {
  text-align: center;
}

.QAndA-card .turn-phone {
  text-align: center;
  color: inherit;
  background-color: #80c6ff;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 5px 15px;
  display: inline-block;
}

@media only screen and (min-width: 450px) {
  .QAndA-card .turn-phone {
    display: none;
  }
}

.QAndA-card .question-disclaimer {
  text-align: center;
  color: inherit;
  background-color: silver;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 5px 15px;
  display: inline-block;
}

.QAndA-card .emoji {
  width: auto;
  height: 1.5rem;
  margin-left: .2rem;
  display: inline-block;
}

.QAndA-card .yed-subtitle {
  font-size: 1.1rem;
  line-height: 1.2;
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  color: var(--chakra-colors-brand-600);
  overflow-wrap: anywhere;
  margin-top: 1rem;
}

.QAndA-card .duodecim h3 {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 700;
}

.QAndA-card .duodecim a span.text {
  font-size: 80%;
  display: none;
  position: relative;
  top: -.5em;
}

.QAndA-card .duodecim table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1.5rem 1px;
}

.QAndA-card .duodecim table caption {
  max-width: 75vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.QAndA-card .duodecim table th {
  color: #fff;
  vertical-align: top;
  text-align: inherit;
  text-align: -webkit-match-parent;
  background-color: #005293;
  padding: .5rem;
  font-weight: 700;
}

.QAndA-card .duodecim table td {
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
  padding: 15px;
  font-size: .875em;
}

.QAndA-card .duodecim table tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.QAndA-card .duodecim table tr:nth-child(2n) {
  background-color: #fff;
}

.QAndA-card .duodecim p + p {
  margin-top: 1rem;
}

.QAndA-card .duodecim li {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.QAndA-card .duodecim .table-container {
  max-width: 75vw;
  overflow-x: auto;
}

.QAndA-card .youtube-container {
  max-width: 640px;
  margin: 0 auto;
}

.QAndA-card .youtube-iframe-wrapper {
  height: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 25px;
  padding-bottom: 56.25%;
  position: relative;
}

.QAndA-card .youtube-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.QAndA-card .druglist summary {
  cursor: pointer;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.QAndA-card .druglist ul {
  margin-bottom: 1rem;
}

.QAndA-card .empty-row {
  height: 1rem;
}

.reportCard .adsume-limits {
  list-style-type: "- ";
  list-style-position: inside;
}

.payment-terms {
  text-align: center;
  max-width: 500px;
  font-size: large;
}

.payment-terms a {
  font-weight: 600;
  text-decoration: underline;
}

/*# sourceMappingURL=index.c535c69b.css.map */
